module.exports = {
  basics: {
    name: "Olle Burlin",
    label: "Frontend developer",
    picture: "",
    email: "burlinolle@gmail.com",
    phone: "+46 768051375",
    website: "https://olleburlin.se",
    summary: "Web designer & developer",
    location: {
      title: `Studio 73A`,
      address: `Jörgen Kocksgatan 73A`,
      postalCode: `211 20`,
      city: "Malmö",
      countryCode: "SE",
      region: "",
    },
    profiles: [
      {
        network: "Instagram",
        username: "olleburlin",
        url: "https://instagram.com/olleburlin",
      },
    ],
  },
  about: [
    {
      title: "Frontend developer",
      startDate: "2006",
      endDate: "",
      summary:
        "I have been building websites since the late nineties but on a more professional level since 2006. I started doing artist websites and promotional projects for the record label I was working at. I use the latest technologies ensuring fast, reliable, search engine friendly and responsive experiencees.",
    },
    {
      title: "Wordpress developer",
      startDate: "2008",
      endDate: "",
      summary:
        "I started doing Wordpress websites from back to front in 2008. I have built over 30 websites with custom themes from scratch. I use wordpress both as a headless CMS and coupled. ",
    },
  ],
  work: [
    {
      company: "Freelance",
      position: "Web Developer & Designer",
      website: "",
      startDate: "2020",
      endDate: "",
      summary:
        "Currently I'm a freelance web developer and graphic designer. I do backend/API, frontend, design as well as content creation.",
      highlights: ["Started the company"],
    },
    {
      company: "Bad Taste Empire",
      position: "Creative Director, Developer",
      website: "http://company.com",
      startDate: "2006",
      endDate: "2019",
      summary:
        "At Bad Taste Empire i managed all artist websites, creative direction, graphic design and content creation.",
      highlights: ["Started the company"],
    },
  ],
  volunteer: [
    {
      organization: "Organization",
      position: "Volunteer",
      website: "http://organization.com/",
      startDate: "2012-01-01",
      endDate: "2013-01-01",
      summary: "Description...",
      highlights: ["Awarded 'Volunteer of the Month'"],
    },
  ],
  education: [
    {
      institution: "University",
      area: "Software Development",
      studyType: "Bachelor",
      startDate: "2011-01-01",
      endDate: "2013-01-01",
      gpa: "4.0",
      courses: ["DB1101 - Basic SQL"],
    },
  ],

  skills: [
    {
      name: "HTML",
      level: "Advanced",
      keywords: ["HTML5", "Semantic", "Clean"],
    },
    {
      name: "CSS",
      level: "Advanced",
      keywords: ["CSS3", "SASS", "LESS", "Bootstrap", "Tailwind"],
    },
    {
      name: "JavaScript",
      level: "Intermediate",
      keywords: ["React", "Node", "Gatsby", "Next", "ES6/ES7"],
    },
  ],
  languages: [
    {
      language: "English",
      fluency: "Native speaker",
    },
  ],
  interests: [
    {
      name: "Wildlife",
      keywords: ["Ferrets", "Unicorns"],
    },
  ],
  references: [
    {
      name: "Jane Doe",
      reference: "Reference...",
    },
  ],
};
