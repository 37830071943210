import React from "react";
import profilePhoto from "@images/olle.jpg";
import resumeData from "@data/resumeData.js";

import Flip from "react-reveal/Flip";

import LayoutPage from "../components/LayoutPage";
import SEO from "../components/seo";

function ContactPage() {
  const about = resumeData.basics;
  return (
    <LayoutPage>
      <SEO
        keywords={[
          `Olle`,
          `Burlin`,
          `Web designer`,
          `React`,
          `ReactJs`,
          `Wordpress`,
        ]}
        title="Kontakt - Olle Burlin"
      />
      <section>
        <Flip left>
          <div className="flex justify-center align-center mt-16 md:-mt-32">
            <div className="flex flex-col items-center md:flex-row bg-white justify-around rounded-xs shadow py-6 px-8">
              <div>
                <img
                  alt="Profile"
                  className="h-20 w-20 md:h-24 md:w-24 rounded-full mx-auto md:mr-8 mb-4 md:mb-0"
                  src={profilePhoto}
                />
              </div>
              <div className="text-center md:text-left px-2">
                <h2 className="text-xl font-bold">{about.name}</h2>
                <div className="text-gray-800 text-md">
                  <div className="mb-1">
                    <a href={`mailto:${about.email}`}>{about.email}</a>
                  </div>
                  <div className="mb-1">{about.phone}</div>
                  <div className="text-sm">{about.location.title}</div>
                  <div className="text-sm">{about.location.address}</div>
                  <div className="text-sm inline-block">
                    {about.location.postalCode}
                  </div>
                  <span>,&nbsp;</span>
                  <div className="text-sm inline-block">
                    {about.location.city}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Flip>
      </section>
    </LayoutPage>
  );
}

export default ContactPage;
